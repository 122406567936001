<template>
  <div v-if="$can('resource_list', 'resource')">
    <v-card>
      <v-form
        ref="searchForm"
        @submit.prevent="toSearch()"
      >
        <v-card-text class="d-flex justify-space-around">
          <div class="d-flex align-center pt-3">
            <label for="searchName"> <span class="text-subtitle-2">资源名称：</span> </label>
            <v-text-field
              id="searchName"
              v-model="search.name"
            ></v-text-field>
          </div>
          <div class="d-flex align-center pt-3">
            <label for="searchUrl"> <span class="text-subtitle-2">资源路径：</span> </label>
            <v-text-field
              id="searchUrl"
              v-model="search.url"
            ></v-text-field>
          </div>
          <div class="d-flex align-center pt-3">
            <label for="categoryId"> <span class="text-subtitle-2">资源分类：</span> </label>
            <v-select
              id="categoryId"
              v-model="search.categoryId"
              :items="selectedData"
            ></v-select>
          </div>

          <div class="d-flex align-center pt-3">
            <v-btn
              color="secondary"
              class="me-3"
              type="submit"
            >
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
              <span>查询</span>
            </v-btn>
          </div>

          <div class="d-flex align-center pt-3">
            <v-btn
              class="me-3"
              @click="resetSearch()"
            >
              <span>重置</span>
            </v-btn>
          </div>
        </v-card-text>
      </v-form>
    </v-card>

    <v-card class="mt-2">
      <v-card-title
        v-if="$can('resource_save', 'resource')"
        class="d-flex align-center flex-wrap pb-0"
      >
        <div class="d-flex align-center pb-5">
          <!-- create invoice -->
          <v-btn
            color="primary"
            class="me-3"
            @click="editItem()"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新增</span>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="{
            itemsPerPageAllText: '全部',
            itemsPerPageText: '每页显示 ',
            showCurrentPage: true
          }"
          :page="pages.page"
          :items-per-page="pages.itemsPerPage"
          :server-items-length="pages.serverItemsLength"
          :headers="getHeader()"
          :items="resources"
          no-data-text="无数据"
          @pagination="loadPage"
        >
          <!-- action -->
          <template #[`item.actions`]="{ item }">
            <v-tooltip
              v-if="$can('resource_edit', 'resource')"
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  class="me-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                >
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </template>
              <span>编辑</span>
            </v-tooltip>
            <v-tooltip
              v-if="$can('resource_delete', 'resource')"
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="openDeleteDialog(item)"
                >
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </template>
              <span>删除</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>

      <v-navigation-drawer
        v-model="editDialog"
        temporary
        touchless
        :right="!$vuetify.rtl"
        :width="$vuetify.breakpoint.smAndUp ? 750 : '100%'"
        app
      >
        <v-card height="100%">
          <div class="drawer-header d-flex align-center">
            <span class="font-weight-semibold text-base text-h6">{{ editedItem.id ? '编辑' : '添加' }}资源</span>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-5"
              color="primary"
              :disabled="commitFlag"
              :loading="commitFlag"
              @click="handleFormSubmit()"
            >
              保存
            </v-btn>
            <v-btn
              icon
              small
              @click="editDialog = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </div>

          <v-card-text
            style="background-color: #F4F5FA; height: 90%; padding-top: 20px;"
            class="overflow-auto"
          >
            <v-card>
              <v-form
                ref="editForm"
                lazy-validation
                @submit.prevent="handleFormSubmit"
              >
                <v-card-text class="pa-3">
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <label for="name"><span style="color: red">*</span>资源名称：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-text-field
                          id="name"
                          v-model="editedItem.name"
                          :rules="rules.required"
                          required
                          label="资源名称"
                          outlined
                          clearable
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <label for="url"><span style="color: red">*</span>资源路径：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-text-field
                          id="url"
                          v-model="editedItem.url"
                          label="路径"
                          :rules="rules.required"
                          required
                          outlined
                          clearable
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <label for="code"><span style="color: red">*</span>资源代码：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-text-field
                          id="code"
                          v-model="editedItem.code"
                          label="资源代码"
                          :rules="rules.required"
                          required
                          outlined
                          clearable
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <label for="category"><span style="color: red">*</span>资源分类：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-select
                          v-model="editedItem.categoryId"
                          :items="selectedData"
                          label="分类"
                          :rules="rules.required"
                          required
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <label for="description">资源描述：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-textarea
                          v-model="editedItem.description"
                          outlined
                          name="input-7-4"
                          label="描述"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-form>
            </v-card>
          </v-card-text>
        </v-card>
      </v-navigation-drawer>

      <!-- <v-dialog
        v-model="editDialog"
        width="600px"
      >
        <v-card tile>
          <v-form
            ref="editForm"
            lazy-validation
            @submit.prevent="handleFormSubmit"
          >
            <v-card-title>
              <span class="text-h6">{{ editedItem.id ? '编辑' : '添加' }}资源</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    md="10"
                    offset="1"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="3"
                        offset="0"
                      >
                        <label for="name"><span style="color: red">*</span>资源名称：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="8"
                      >
                        <v-text-field
                          id="name"
                          v-model="editedItem.name"
                          :rules="rules.required"
                          required
                          label="资源名称"
                          outlined
                          clearable
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                    cols="12"
                    md="10"
                    offset="1"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="3"
                        offset="0"
                      >
                        <label for="url"><span style="color: red">*</span>资源路径：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="8"
                      >
                        <v-text-field
                          id="url"
                          v-model="editedItem.url"
                          label="路径"
                          :rules="rules.required"
                          required
                          outlined
                          clearable
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    md="10"
                    offset="1"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="3"
                        offset="0"
                      >
                        <label for="code"><span style="color: red">*</span>资源代码：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="8"
                      >
                        <v-text-field
                          id="code"
                          v-model="editedItem.code"
                          label="资源代码"
                          :rules="rules.required"
                          required
                          outlined
                          clearable
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    md="10"
                    offset="1"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="3"
                        offset="0"
                      >
                        <label for="category"><span style="color: red">*</span>资源分类：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="8"
                      >
                        <v-select
                          v-model="editedItem.categoryId"
                          :items="selectedData"
                          label="分类"
                          :rules="rules.required"
                          required
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    md="10"
                    offset="1"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="3"
                        offset="0"
                      >
                        <label for="description">资源描述：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="8"
                      >
                        <v-textarea
                          v-model="editedItem.description"
                          outlined
                          name="input-7-4"
                          label="描述"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="normal"
                text
                @click="editDialog = false"
              >
                关闭
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="handleFormSubmit()"
              >
                保存
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog> -->
      <v-dialog
        v-model="deleteDialog"
        width="300"
      >
        <v-card>
          <v-card-text> 是否删除 {{ this.deleteData.name }} ? </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="deleteItem()"
            >
              确认删除
            </v-btn>
            <v-btn
              color="normal"
              text
              @click="deleteDialog = false"
            >
              取消
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import {
  mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiClose, mdiMagnify,
} from '@mdi/js'
import CategoryApi from '@/api/system/resource/category'
import ResourceApi from '@/api/system/resource/resource'

export default {
  name: 'Resource',
  components: {},
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiMagnify,
      },
      pages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
      },
      rules: {
        required: [v => !!v || '该字段不能为空'],
      },
      resources: [],
      editDialog: false,
      deleteDialog: false,
      selectedData: [],
      editedItem: {},
      defaultItem: {
        name: '',
        description: '',
        url: '',
      },
      search: {},
      loadings: {
        submitLoading: false,
      },
      deleteData: {},
      commitFlag: false,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    getHeader() {
      const baseHeader = [
        { text: '资源名称', value: 'name' },
        { text: '资源路径', value: 'url' },
        { text: '资源代码', value: 'code' },
        { text: '资源描述', value: 'description' },

      ]
      if (this.$can('resource_delete', 'resource')
          || this.$can('resource_edit', 'resource')) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    async editItem(item) {
      if (item) {
        this.editedItem = item
      } else {
        this.initEditedItem()
        this.$nextTick(() => {
          const { editForm } = this.$refs

          editForm.resetValidation()
        })
      }
      this.commitFlag = false
      this.editDialog = true
    },
    deleteItem() {
      ResourceApi.delete(this.deleteData.id).then(res => {
        this.$toast.success('删除成功！')
        this.deleteDialog = false
        this.loadPage(this.pages)
      })
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.editForm.validate()
      if (!isFormValid) return
      if (this.commitFlag) return
      this.commitFlag = true
      ResourceApi.save(this.editedItem).then(res => {
        this.$toast.success('保存成功')
        this.commitFlag = false
        this.closeDialog()
        this.loadPage({ page: this.pages.page, itemsPerPage: this.pages.itemsPerPage })
      })
    },
    loadData() {
      if (this.$can('resource_category_list', 'resource')) {
        CategoryApi.list().then(res => {
          const { data } = res.data
          const category = []
          data.forEach(item => {
            const obj = { text: item.name, value: item.id }
            category.push(obj)
          })
          this.selectedData = category
        })
      }
    },
    loadPage({ page, itemsPerPage }) {
      const data = {
        rows: itemsPerPage,
        page,
        name: this.search.name,
        url: this.search.url,
        categoryId: this.search.categoryId,
      }
      ResourceApi.getResourcePages(data).then(res => {
        const { data } = res.data
        this.pages.page = data.current
        this.resources = data.records
        if (data.size === -1) {
          this.pages.serverItemsLength = this.resources.length
        } else {
          this.pages.serverItemsLength = data.total
        }
      })
    },
    closeDialog() {
      this.$refs.editForm.resetValidation()
      this.editedItem = this.defaultItem
      this.editDialog = false
    },
    openDeleteDialog(data) {
      this.deleteData = data
      this.deleteDialog = true
    },
    resetSearch() {
      this.$refs.searchForm.reset()
      this.toSearch()
    },
    toSearch() {
      this.loadPage({ page: 1, itemsPerPage: this.pages.itemsPerPage })
    },
    initEditedItem() {
      this.defaultItem.id = ''
      this.defaultItem.code = ''
      this.defaultItem.name = ''
      this.defaultItem.description = ''
      this.defaultItem.url = ''
      this.defaultItem.categoryId = -1
      this.editedItem = this.defaultItem
    },
  },
}
</script>
