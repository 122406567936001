import axios from '@axios'
import qs from 'qs'

const prefixPath = '/api/system/resource'
export default {

  // get(id) {
  //   return axios.get(`${prefixPath}/${id}`)
  // },

  save(data) {
    return axios.post(`${prefixPath}/saveOrUpdate`, data)
  },

  list() {
    return axios.get(`${prefixPath}/getAll`)
  },

  getResourcePages(data) {
    return axios.post(`${prefixPath}/getResourcePages`, data)
  },

  delete(id) {
    return axios.post(`${prefixPath}/delete`, qs.stringify({
      id,
    }))
  },

  getRoleResources(roleId) {
    return axios.get(`${prefixPath}/getRoleResources`, {
      params: {
        roleId,
      },
    })
  },

  allocateRoleResources(data) {
    return axios.post(`${prefixPath}/allocateRoleResources`, data)
  },

}
